.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*font-size: calc(10px + 2vmin);*/
  color: black;
}

.App-link {
  color: #61dafb;
}
.nav-link {
  color: #0d6efd !important
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sv-logo--left  {
  position: relative;
  top: -10px;
}

.App {
  /* background-image: url(.././img/baby_bg.jpg); */
  background-size: cover;
  background-position: top;
  min-height: 100%;
  height: 100vh;
  position: relative;
}
.react-virtualized-menu-placeholder {
  margin-top: 12px;
  color: #9a9a9a;
  text-align: center;
}
.react-virtualized-list-wrapper li {
  list-style: none;
}

.custom-option {
  line-height: 120%;
  transition: background 60ms;
  margin-bottom: 100%;
  padding-bottom: 0%;
  /* padding:0 */
}
.custom-option:hover {
  transition-delay: 60ms;
  background: #deebff;
  /* padding:0 */
}
.custom-option.custom-select__option--is-focused {
  background: #deebff;
  margin-bottom: 100%;
  padding-bottom: 0%;
}
.custom-option.custom-select__option--is-selected {
  background: #2684FF;
  padding-bottom: 0%;
}



.multiselect-button-dropdown{
  font-family: Raleway !important;
  font-weight: bold !important;
  background: #c0f4dd !important;
  border-color: #c0f4dd !important;
  border-bottom: 1px solid #c0f4dd !important;
  border-radius: 5% !important;
  padding-bottom: 5% !important;
  padding-top: 5% !important;
  box-shadow: none;
  
  /* border-bottom: 0px !important; */
  /* width: 25% !important; */
}

.multiselect-section-wrapper{
  background: #c0f4dd;
  /* border-color: #c0f4dd !important; */
  padding-right: 10% !important;
  display:block !important;
  z-index: 100 !important;
}

.multiselect-button-select-all{
  font-size: small;
  color: rgb(92, 92, 173) !important;
  font-weight: bold !important;
}

.multiselect-reset-button{
  font-size: small;
  color: rgb(92, 92, 173) !important;
  font-weight: bold !important;
}

