.sv_progress-buttons__list li {
  width: 150px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sv-root-modern .sv-selectbase__clear-btn {
  background-color: #ccc !important
}


.__react_component_tooltip {
  pointer-events: auto !important;
}

.react-bootstrap-table-pagination-total {
  float: left !important;
  margin-top: 0.5em !important;
}

.btn .btn-default .btn-secondary .dropdown-toggle {
  float: left !important;
}

#pageDropDown {
  float: left !important;
}

.clickableLink {
  /* font-size: 20px !important; */
  pointer-events: auto !important;

}

.clickableLink :hover {
  visibility: visible !important;
  opacity: 1 !important;
}

.sv-tagbox__item {
  max-width: 75% !important
}

.sv-tagbox__item-text {
  min-width: 100% !important
}